import React from "react";
import { Col, Row, Container } from "../../Grid";
import "./style.css";


function Contact() {
    return (
        <div className="lightBackground contactContainer topMargin">
        <Container fluid >
          <Row>
            <Col size="md-12">
              <h1>Contact Info</h1>
              <ul>
                  <p >email: <a  className="contactStyle" href= "mailto:me@coryleesayer.photography">me@coryleesayer.photography</a></p>
                  <p >phone: <span className="contactStyle">+1 (385) 212-4913</span></p>
              </ul>
            </Col>
          </Row>

        </Container>
        </div>
    );
}
export default Contact;