import React from "react";
import "./style.css";

function Background() {
    return (
        <ul className="cb-slideshow">
        <li>
          <span>Image 01</span>
        </li>
        <li>
          <span>Image 02</span>
        </li>
        <li>
          <span>Image 02</span>
        </li>
        <li>
          <span>Image 02</span>
        </li>
        <li>
          <span>Image 02</span>
        </li>
        <li>
          <span>Image 02</span>
        </li>
      </ul>
    );
  }
  
  export default Background;
  