import React from "react";
import { Col, Row, Container } from "../../Grid";
import ImgCard from "../../imgCard";
import barrel1 from "../../../assets/images/barrel1.jpg";
import tabiona1 from "../../../assets/images/tabiona1.jpg";
import vernal1 from "../../../assets/images/vernal1.jpg";
import buckaroo1 from "../../../assets/images/buckaroo1.jpg";
import bullRider_17 from "../../../assets/images/Bull_Riding-17.jpg"
import "./style.css";

function PhotoEvents() {
  return (
    <div className="lightBackground topMargin">
      <Container fluid>
        <Row>
          <Col size="md-12">
            <h1>Events</h1>
            <h3>Click the event below. Email me the image names you would like to purchase.</h3>
            <Row topMargin="topMargin">
              <Col size="md-3">
                <a href="https://1drv.ms/u/s!AgOtT0y6ySGzmwJD7dtkyZshjOo6?e=AIkacp" target="_blank" rel="noopener noreferrer">
                  <ImgCard image={barrel1} cardTitle={"Barrel Races Duchesne"} cardText={"May 2nd 2020"} />
                </a>
              </Col>
              <Col size="md-3">
                <a href="https://1drv.ms/u/s!AgOtT0y6ySGznVGmKXPZZX2x0fHj?e=9E5BIU" target="_blank" rel="noopener noreferrer">
                  <ImgCard image={barrel1} cardTitle={"Barrel Races Duchesne"} cardText={"May 5th 2020"} />
                </a>
              </Col>
              <Col size="md-3">
                <a href="https://1drv.ms/f/s!AgOtT0y6ySGznzwHg-XMo0uBSytW" target="_blank" rel="noopener noreferrer">
                  <ImgCard image={barrel1} cardTitle={"Barrel Races Duchesne"} cardText={"May 12th 2020"} />
                </a>
              </Col>
              <Col size="md-3">
                <a href="https://1drv.ms/f/s!AgOtT0y6ySGzomoT5BE8e5hBVVxT" target="_blank" rel="noopener noreferrer">
                  <ImgCard image={barrel1} cardTitle={"Barrel Races Duchesne"} cardText={"May 19th 2020"} />
                </a>
              </Col>
            </Row>
            <Row topMargin="topMargin">
              <Col size="md-3">
                <a href="https://1drv.ms/f/s!AgOtT0y6ySGzpFYmDoKt2CcgCDVF" target="_blank" rel="noopener noreferrer">
                  <ImgCard image={barrel1} cardTitle={"Barrel Races Duchesne"} cardText={"May 25th 2020"} />
                </a>
              </Col>
              <Col size="md-3">
                <a href="https://1drv.ms/f/s!AgOtT0y6ySGzr3Q6vD6Sbeb3GLBM" target="_blank" rel="noopener noreferrer">
                  <ImgCard image={barrel1} cardTitle={"Barrel Races Duchesne"} cardText={"June 2nd 2020"} />
                </a>
              </Col>
              <Col size="md-3">
                <a href="https://1drv.ms/f/s!AgOtT0y6ySGzvjEblcHqkYzZTOHF" target="_blank" rel="noopener noreferrer">
                  <ImgCard image={barrel1} cardTitle={"Barrel Races Duchesne"} cardText={"June 16th 2020"} />
                </a>
              </Col>
              <Col size="md-3">
                <a href="https://1drv.ms/f/s!AgOtT0y6ySGzwTDOuLzwLl-iXRbS" target="_blank" rel="noopener noreferrer">
                  <ImgCard image={tabiona1} cardTitle={"Dinosaur Roundup Rodeo"} cardText={"Tabiona"} />
                </a>
              </Col>
            </Row>
            <Row topMargin="topMargin">
              <Col size="md-3">
              <a href="https://1drv.ms/f/s!AgOtT0y6ySGzy0_c25_QjVgIuFOF" target="_blank" rel="noopener noreferrer">
                  <ImgCard image={vernal1} cardTitle={"Dinosaur Roundup Rodeo Vernal"} cardText={"July 11th 2020"} />
                </a>
              </Col>
              <Col size="md-3">
              <a href="https://1drv.ms/f/s!AgOtT0y6ySGz0FL2bocWk0oJHvbt" target="_blank" rel="noopener noreferrer">
                  <ImgCard image={buckaroo1} cardTitle={"Little Buckaroo"} cardText={"August"} />
                </a>
              </Col>
              <Col size="md-3">
              <a href="https://1drv.ms/f/s!AgOtT0y6ySGz3wO3ZSUX4wpX8R_x" target="_blank" rel="noopener noreferrer">
                  <ImgCard image={bullRider_17} cardTitle={"Duchesne"} cardText={"September 12-13"} />
                </a>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default PhotoEvents;
