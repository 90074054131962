import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import NavTabs from "./components/navBar/";
import Footer from "./components/footer/"
import Portfolio from "./components/pages/portfolio/index";
import PhotoEvents from "./components/pages/photoEvents";
import About from "./components/pages/about/index";
import NoMatch from "./components/pages/noMatch/index";
import Contact from "./components/pages/contact/index";
import Background from "./components/background";
// import BackgroundSlideshow from "react-background-slideshow";

// import image1 from "./assets/images/lake1138";
// import image2 from "./assets/images/sunsetlake1138";
// import image3 from "./assets/images/ir1138";
// import image4 from "./assets/images/welder1138";
// import image5 from "./assets/images/steve1138";

import "./App.css";

function App() {
  return (
    <Router>
      <div className="App">
        <Background />
        {/* <BackgroundSlideshow images={[image1, image2, image3, image4, image5]} /> */}
        <NavTabs />
        <Switch>
          <Route exact path="/coryleesayerphotography/" component={About} />
          <Route exact path="/" component={About} />
          <Route exact path="/portfolio" component={Portfolio} />
          <Route exact path="/coryleesayerphotography/contact" component={Contact} />
          <Route exact path="/coryleesayerphotography/portfolio" component={Portfolio} />
          <Route exact path="/contact" component={Contact} />
          <Route component={NoMatch} />
          </Switch>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
