import React from "react";
import { Col, Row, Container } from "../../Grid";
import "./style.css";
import bioPic from "../../../assets/images/biopic.jpg"

const styles ={
  cardImg: {
    width: "16rem"
  }
}

function About() {
  
  return (
    <div className="lightBackground topMargin">

      <Container fluid>
        <Row>
          <Col size="md-12">
          <div className="card-group">
            <div className="card cardAbout">
              <img style={styles.cardImg}
                src={bioPic}
                className="rounded-circle card-img-top"
                alt="bio"
              />
            </div>
            <div className="card cardAbout">
              <div className="card-body align-items-center d-flex">
                <p className="card-text">My passion is to capture the moments no one else notices. I have been a professional photographer for 10 years and would love to help you capture your most precious moments.</p>
              </div>
            </div>
            <div className="card cardAbout">
              <div className="card-body align-items-center d-flex">
                <p className="card-text">Utah based photographer. Landscape wildlife portrait weddings. The photographs I show are my work. I hope you enjoy them. For booking information use the contact tab. </p>
              </div>
            </div>
          </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default About;
