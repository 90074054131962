import React from "react";
import { NavLink } from "react-router-dom";
import "./style.css";

function NavTabs() {
  return (
    <ul className="nav nav-tabs ">
      <li className="nav-item">
        <NavLink to="/" className= "nav-link" exact activeClassName="active">
          About
        </NavLink>
      </li>
      <li className="nav-item">
        <NavLink to="/portfolio" className= "nav-link" exact activeClassName="active">
          Portfolio
        </NavLink>
      </li>
      <li className="nav-item">
        <NavLink to="/contact" className= "nav-link" exact activeClassName="active">
          Contact
        </NavLink>
      </li>
      <li className="nav-item">
      <a target="_blank" href="https://store.coryleesayer.photography" className= "nav-link">Store</a>
      </li>

    </ul>
  );
}
export default NavTabs;
