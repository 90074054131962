import React from "react";
import { Col, Row, Container } from "../../Grid";
import ImgCard from "../../imgCard";
import "./style.css";
import wedding from "../../../assets/images/wedding.jpg";
import wildlife from "../../../assets/images/wildlife.jpg";
import pano from "../../../assets/images/pano.jpg";
import portraitWeb from "../../../assets/images/portraitWeb.jpg";

// eslint-disable-next-line
const cardStyle = {
  width: "18rem"
};

function Porfolio() {
  return (
    <Container fluid>
      <Row topMargin="topMargin">
        <Col size="md-3">
          <ImgCard image={wedding} cardTitle={"Weddings"} cardText={""}/>
        </Col>
        <Col size="md-3">
          <ImgCard image={wildlife} cardTitle={"Wildlife"} cardText={""}/>
        </Col>
        <Col size="md-3">
          <ImgCard image={portraitWeb} cardTitle={"Portrait"} cardText={""}/>
        </Col>
        <Col size="md-3">
          <ImgCard image={pano} cardTitle={"Panoramic"} cardText={""}/>
        </Col>
      </Row>
    </Container>
  );
}

export default Porfolio;
