import React from "react";

function ImgCard(props) {
    return (
        <div className="card" style={props.cardStyle}>
        <img src={props.image} className="card-img-top" alt="..." />
        <div className="card-body">
          <h5 className="card-title">{props.cardTitle}</h5>
          <p className="card-text">{props.cardText}</p>
        </div>
      </div>
    );
  }
  
  export default ImgCard;
  