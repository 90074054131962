import React from "react";
import "./style.css";
import instagram from "../../assets/images/glyph-logo_May2016.png"


function Footer() {
    return (
        <footer className="footer">
        <div className="container">
          <span className="text-muted">Cory Lee Sayer Photopraphy LLC Copyright &copy;{new Date().getFullYear()}</span><span id="instagramSpan"><a href="https://instagram.com/cory_sayer_photography?igshid=1xdoqbg11pfqk" target="_blank" rel="noopener noreferrer"><img style={{"backgroundColor": "white"}} id="instagram" alt="instagram logo"src={instagram} /></a></span>
        </div>
      </footer>

    );
}

export default Footer